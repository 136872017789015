import { graphql, Link, StaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Nav from '../components/UI/Nav';
import colombiaFintech from '../img/colombia-fintech.webp';
import facebook from '../img/facebook.png';
import fintrust from '../img/fintrust.webp';
import footerPathGray from '../img/footer-path-gray.svg';
import footerPathWhite from '../img/footer-path-white.svg';
import instagram from '../img/instagram.png';
import linkedin from '../img/linkedin.png';
import camara from '../img/logo-camara-ea.webp';
import gc from '../img/logo-gc.webp';
import logo from '../img/logo-juancho-te-presta.webp';
import sicFacilita from '../img/sic-facilita.webp';
import vigilado from '../img/sic.webp';
import threads from '../img/threads.png';
import tikTok from '../img/tikTok.png';
import twitter from '../img/twitter.png';
import youtube from '../img/youtube.png';
import { breakpointLarge } from '../styles/breakpoints';
import ContentContainer from './UI/ContentContainer';

const FooterStyled = styled.footer`
  background-color: ${props => (props.home ? '#fff' : props.theme.grayLight)};
`;
const FooterOuterContainer = styled.div`
  background-color: ${props => props.theme.gray};
  background-image: url(${p => (p.home ? footerPathWhite : footerPathGray)});
  background-repeat: no-repeat;
  background-position: center -1px;
  width: 100%;
  background-size: contain;
  padding: 0 1.5rem 1rem;
  margin-top: 0.2rem;
`;
const ContentContainerStyled = styled(ContentContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ContentLogo2 = styled(ContentContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height:45px;
  @media (min-width: ${breakpointLarge}) {
   height:80px;
  }
`;
const Logo = styled.img`
  width: 100px;
  height: 100px
  margin-top: -0.75rem;
  @media (min-width: ${breakpointLarge}) {
    width: 210px;
    height: 210px;
  }
`;
const Logo2 = styled.img`
  position:absolute;
  top:-40px;
  width: 80px;
  height: 80px
  margin-top: -0.75rem;
  @media (min-width: ${breakpointLarge}) {
    top:-55px;
    width: 110px;
    height: 110px;
  }
`;
const SocialNetworks = styled.div`
  margin-top: 1rem;
  a {
    margin: 0.25rem;
  }
  img {
    width: 1.3rem;
    height: 1.3rem;
    @media (min-width: ${breakpointLarge}) {
      width: 2.3rem;
      height: 2.3rem;
    }
  }
`;

const RelevantInformation = styled.div`
  height: auto;
  background-color: ${props => props.theme.gray};
  color: white;
  text-align: center;
  p {
    font-size: 8px;
    line-height: 0.3em;
    @media (min-width: ${breakpointLarge}) {
      font-size: 12px;
      line-height: 0.5em;
    }
  }
`;
const Menu = styled(Nav)`
  background-color: ${props => props.theme.gray};
  display:flex;
  justify-content:center;
  ul {
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    max-width:800px;
  }
  li {
    margin:0 0.5rem 0 0.5rem;
    text-align: center;
  }
`;
const LinkStyled = styled(Link)`
  color: ${props => props.theme.yellow};
  text-decoration: none;
  margin:0;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.0625rem;
  @media (min-width: ${breakpointLarge}) {
    font-size: 1rem;
    line-height: 0.5em;
  }
`;
const ContainerImgs = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: repeat(2, 1fr); 
  grid-template-columns: repeat(3, 1fr); 
  gap: 10px;
  margin-bottom:2rem;
  @media (min-width: ${breakpointLarge}) {
    margin-bottom:3rem;
  }
  
`
const SectionImg = styled.div`
  display:flex;
  align-items:end;
  justify-content:center;
  > a > img {
    width: 210px;
  }
  @media (max-width: ${breakpointLarge}) {
    bottom: 2.5rem;
    > a > img {
      width: 50px;
    }
  }
`
const FooterComponent = ({ isHome = false, tasaMaxima, tasaInteres }) => {

  return (
    <>
      {isHome === false ? ("") : (
        <FooterStyled home={isHome}>
          <FooterOuterContainer home={isHome}>
            <ContentContainerStyled>
              <Logo src={logo} alt="Juancho te presta" />
              <ContainerImgs>
                <SectionImg>
                  <a
                    href='https://www.sic.gov.co/'
                    title='https://www.sic.gov.co/'
                    target='_blanck'
                  >
                    <img src={vigilado} alt="Industria y comercio superintendencia" />
                  </a>
                </SectionImg>
                <SectionImg>
                  <a
                    href='https://colombiafintech.co/'
                    title='https://colombiafintech.co/'
                    target='_blanck'
                  >
                    <img src={colombiaFintech} alt="Colombia Fintech" />
                  </a>
                </SectionImg>
                <SectionImg>
                  <a
                    href='https://colombiafintech.co/'
                    title='https://colombiafintech.co/'
                    target='_blanck'
                  >
                    <img src={fintrust} alt="fintrust" />
                  </a>
                </SectionImg>
                <SectionImg>
                  <a
                    href='https://andidelfuturo.com/'
                    title='https://andidelfuturo.com/'
                    target='_blanck'
                  >
                    <img src={camara} alt="camara" />
                  </a>
                </SectionImg>
                <SectionImg>
                  <a
                    href='https://sicfacilita.sic.gov.co/SICFacilita/index.xhtml'
                    title='https://sicfacilita.sic.gov.co/SICFacilita/index.xhtml'
                    target='_blanck'
                  >
                    <img src={sicFacilita} alt="Sic facilita" />
                  </a>
                </SectionImg>
                <SectionImg>
                  <a
                    href="https://www.thegcindex.com/a-gc-people-company/"
                    title="https://www.thegcindex.com/a-gc-people-company/"
                    target='_blanck'
                  >
                    <img src={gc} alt="gc" />
                  </a>
                </SectionImg> 
              </ContainerImgs>
            </ContentContainerStyled>
          </FooterOuterContainer>
        </FooterStyled>
      )}

      <RelevantInformation style={isHome === false ? { marginTop: "80px" } : {}}>
        {isHome === false ? (
          <ContentLogo2 >
            <Logo2 src={logo} alt="Juancho te presta" />
          </ContentLogo2>) : ("")}
        <SocialNetworks>
          <a
            title="Facebook"
            href="https://jtp.lat/LcE"
          >
            <img src={facebook} alt="Facebook" />
          </a>
          <a
            title="Instagram"
            href="https://jtp.lat/OvO"
          >
            <img src={instagram} alt="Instagram" />
          </a>
          <a
            title="twitter"
            href="https://jtp.lat/yQd"
          >
            <img src={twitter} alt="twitter" />
          </a>
          <a
            title="tikTok"
            href="https://jtp.lat/Dmj"
          >
            <img src={tikTok} alt="tikTok" />
          </a>
          <a
            title="youtube"
            href="https://jtp.lat/BbX"
          >
            <img src={youtube} alt="youtube" />
          </a>
          <a
            title="linkedin"
            href="https://jtp.lat/Zbl"
          >
            <img src={linkedin} alt="linkedin" />
          </a>
          <a
            title="Threads"
            href="https://jtp.lat/DWI"
          >
            <img src={threads} alt="Threads" />
          </a>
        </SocialNetworks>
        <p>Tasa de interés: {tasaInteres}% EA.</p>
        <p>Tasa máxima de interés vigente: {tasaMaxima}% EA.</p>
        <p>
          Dirección física: Juancho Te Presta SAS. Km 2, vía El Tambo; Andes
          BPO. La Ceja, Antioquia.
        </p>
        <p>Línea gratuita nacional: 018000413811</p>
      </RelevantInformation>
      {
        isHome === true ? (
          <Menu home={isHome}>
            <ul>
              <li>
                <LinkStyled to="/faq/">Preguntas frecuentes</LinkStyled>
              </li>
              <li>
                <LinkStyled to="/fraude/">Todos contra el fraude</LinkStyled>
              </li>
              <li>
                <LinkStyled to="/terminos-condiciones/">
                  Términos y condiciones
                </LinkStyled>
              </li>
              <li>
                <LinkStyled to="/politicas-privacidad/">
                  Políticas de privacidad
                </LinkStyled>
              </li>
              <li>
                <LinkStyled to="/avisos-importantes/">
                  Avisos importantes
                </LinkStyled>
              </li>
              <li>
                <LinkStyled to="/medios-de-pago/">Medios de pago</LinkStyled>
              </li>
              <li>
                <LinkStyled to="/legal/">Legal</LinkStyled>
              </li>
            </ul>
          </Menu>
        ) : ("")
      }
    </>
  );
};

export default function Footer({ isHome = true }) {

  return (
    <StaticQuery
      query={graphql`
        query prueba {
          markdownRemark(frontmatter: { templateKey: { eq: "footer" } }) {
              frontmatter {
                tasaInteres
                tasaMaxima
              }
          }
        }
      `}
      render={data => {
        return (
          <FooterComponent
            tasaInteres={parseFloat(data.markdownRemark.frontmatter.tasaInteres)}
            tasaMaxima={parseFloat(data.markdownRemark.frontmatter.tasaMaxima)}
            isHome={isHome} />
        )
      }}
    />
  )
}
